angular.module('app.services', [])
.factory('PaginatedDataTable', ['$rootScope', ($rootScope) -> (config) ->
  self = $rootScope.$new()
  self.customTable =
    _allData: []
    params:
      limit: 24
      page: 1
    pagination: []
    data:
      display: []
      pages: 0
    transformData: () ->
      pageIndex = this.params.page - 1
      limit = this.params.limit
      this._allData = this.sortData(this._allData)
      this._filteredData = this.filterData(this._allData)
      this.data.display = this._filteredData.slice(pageIndex * limit, pageIndex * limit + limit)
      this.data.pages = Math.ceil(this._filteredData.length / limit)



      # calculating pagination array
      if (this.data.pages <= 5)
        this.pagination = [1..this.data.pages]
      else
        # fill the first page
        this.pagination = [1]

        # fill the middle pages
        midBefore = undefined
        mid = undefined
        midAfter = undefined

        if (this.params.page <= 2 )
          midBefore = 2
        else if this.params.page < this.data.pages - 1
          midBefore = this.params.page - 1
        else
          midBefore = this.data.pages - 3

        mid = midBefore + 1
        midAfter = mid + 1

        if (midBefore - 1 > 1)
          this.pagination.push(undefined )

        this.pagination.push(midBefore)
        this.pagination.push(mid)
        this.pagination.push(midAfter)

        if (midAfter + 1 < this.data.pages)
          this.pagination.push(undefined)

        # fill the last page
        this.pagination.push(this.data.pages)
    filterData: (data) -> data
    sortData: (data) -> data
    setData: (data) ->
      this._allData = data
      this.transformData()
    setPage: (page) ->
      if (isNaN(page) || page < 0 || page > this.data.pages)
        return
      this.params.page = page
      this.transformData()
      this.postPageChange()
    postPageChange: () -> {}

  if(config?.sortData)
    self.customTable.sortData = config.sortData
  if(config?.filterData)
    self.customTable.filterData = config.filterData
  if (config?.postPageChange)
    self.customTable.postPageChange = config.postPageChange
  if (config?.params)
    self.customTable.params = Object.assign(self.customTable.params, config.params)

  return self.customTable
])
.factory('smoothScr', ['$window', ($window) ->
  scroll: (elementId, offset = 0) ->
    scrollPage = new SmoothScroll()
    scrollPage.animateScroll(document.querySelector(elementId), 0, {updateURL: false, header: '.header__placeholder', offset: offset, speed: 200, speedAsDuration: true})
])
.factory('BookmarkService', ['$rootScope','$timeout', (root, $timeout) ->
  storageName = 'bookmarks'

  # get the saved bookmark list
  localDataString = localStorage.getItem(storageName)
  localData = {}
  root.savedArticles = undefined

  if localDataString
    localData = JSON.parse(localDataString)

  loadArticlesData  = () ->
    savedArticleIds = Object.keys(localData).map((key) -> key.replace("key",""))
    articleObj = angular.copy(localData)
    Object.keys(articleObj).forEach((key) -> articleObj[key] = false)
    root.articles.forEach((article) ->
      if(savedArticleIds.includes(article.id.toString()))
        articleObj["key".concat(article.id)] = article
    )
    root.savedArticles = Object.values(articleObj).filter((val) -> !!val)

  if(root.articles)
    loadArticlesData()
  else
    root.$on('articlesLoaded', (event, data) ->
      loadArticlesData()
    )

  saveData = () ->
    localStorage.setItem(storageName, JSON.stringify(localData))
    loadArticlesData()

  serviceInstance =
    addItem: (id) ->
      localData["key".concat(id)] = true
      saveData()
      jQuery.notify("Artikel in die Merkliste gelegt", {
        autoHide: true,
        autoHideDelay: 2000,
        className: 'success',
        position: 'top right'
      })
    hasItem: (id) ->
      return !!localData["key".concat(id)]
    removeItem: (id) ->
      delete localData["key".concat(id)]
      saveData()
      jQuery.notify("Artikel von Merkliste entfernt", {
        autoHide: true,
        autoHideDelay: 2000,
        className: 'danger',
        position: 'top right'
      })
    removeAll: () ->
      localData ={}
      saveData()

  return serviceInstance
])