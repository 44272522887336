angular.module('app.services', []).factory('PaginatedDataTable', [
  '$rootScope',
  function($rootScope) {
    return function(config) {
      var self;
      self = $rootScope.$new();
      self.customTable = {
        _allData: [],
        params: {
          limit: 24,
          page: 1
        },
        pagination: [],
        data: {
          display: [],
          pages: 0
        },
        transformData: function() {
          var limit,
  mid,
  midAfter,
  midBefore,
  pageIndex,
  ref;
          pageIndex = this.params.page - 1;
          limit = this.params.limit;
          this._allData = this.sortData(this._allData);
          this._filteredData = this.filterData(this._allData);
          this.data.display = this._filteredData.slice(pageIndex * limit,
  pageIndex * limit + limit);
          this.data.pages = Math.ceil(this._filteredData.length / limit);
          // calculating pagination array
          if (this.data.pages <= 5) {
            return this.pagination = (function() {
              var results = [];
              for (var i = 1, ref = this.data.pages; 1 <= ref ? i <= ref : i >= ref; 1 <= ref ? i++ : i--){ results.push(i); }
              return results;
            }).apply(this);
          } else {
            // fill the first page
            this.pagination = [1];
            // fill the middle pages
            midBefore = void 0;
            mid = void 0;
            midAfter = void 0;
            if (this.params.page <= 2) {
              midBefore = 2;
            } else if (this.params.page < this.data.pages - 1) {
              midBefore = this.params.page - 1;
            } else {
              midBefore = this.data.pages - 3;
            }
            mid = midBefore + 1;
            midAfter = mid + 1;
            if (midBefore - 1 > 1) {
              this.pagination.push(void 0);
            }
            this.pagination.push(midBefore);
            this.pagination.push(mid);
            this.pagination.push(midAfter);
            if (midAfter + 1 < this.data.pages) {
              this.pagination.push(void 0);
            }
            // fill the last page
            return this.pagination.push(this.data.pages);
          }
        },
        filterData: function(data) {
          return data;
        },
        sortData: function(data) {
          return data;
        },
        setData: function(data) {
          this._allData = data;
          return this.transformData();
        },
        setPage: function(page) {
          if (isNaN(page) || page < 0 || page > this.data.pages) {
            return;
          }
          this.params.page = page;
          this.transformData();
          return this.postPageChange();
        },
        postPageChange: function() {
          return {};
        }
      };
      if ((config != null ? config.sortData : void 0)) {
        self.customTable.sortData = config.sortData;
      }
      if ((config != null ? config.filterData : void 0)) {
        self.customTable.filterData = config.filterData;
      }
      if ((config != null ? config.postPageChange : void 0)) {
        self.customTable.postPageChange = config.postPageChange;
      }
      if ((config != null ? config.params : void 0)) {
        self.customTable.params = Object.assign(self.customTable.params,
  config.params);
      }
      return self.customTable;
    };
  }
]).factory('smoothScr', [
  '$window',
  function($window) {
    return {
      scroll: function(elementId,
  offset = 0) {
        var scrollPage;
        scrollPage = new SmoothScroll();
        return scrollPage.animateScroll(document.querySelector(elementId),
  0,
  {
          updateURL: false,
          header: '.header__placeholder',
          offset: offset,
          speed: 200,
          speedAsDuration: true
        });
      }
    };
  }
]).factory('BookmarkService', [
  '$rootScope',
  '$timeout',
  function(root,
  $timeout) {
    var loadArticlesData,
  localData,
  localDataString,
  saveData,
  serviceInstance,
  storageName;
    storageName = 'bookmarks';
    // get the saved bookmark list
    localDataString = localStorage.getItem(storageName);
    localData = {};
    root.savedArticles = void 0;
    if (localDataString) {
      localData = JSON.parse(localDataString);
    }
    loadArticlesData = function() {
      var articleObj,
  savedArticleIds;
      savedArticleIds = Object.keys(localData).map(function(key) {
        return key.replace("key",
  "");
      });
      articleObj = angular.copy(localData);
      Object.keys(articleObj).forEach(function(key) {
        return articleObj[key] = false;
      });
      root.articles.forEach(function(article) {
        if (savedArticleIds.includes(article.id.toString())) {
          return articleObj["key".concat(article.id)] = article;
        }
      });
      return root.savedArticles = Object.values(articleObj).filter(function(val) {
        return !!val;
      });
    };
    if (root.articles) {
      loadArticlesData();
    } else {
      root.$on('articlesLoaded',
  function(event,
  data) {
        return loadArticlesData();
      });
    }
    saveData = function() {
      localStorage.setItem(storageName,
  JSON.stringify(localData));
      return loadArticlesData();
    };
    serviceInstance = {
      addItem: function(id) {
        localData["key".concat(id)] = true;
        saveData();
        return jQuery.notify("Artikel in die Merkliste gelegt",
  {
          autoHide: true,
          autoHideDelay: 2000,
          className: 'success',
          position: 'top right'
        });
      },
      hasItem: function(id) {
        return !!localData["key".concat(id)];
      },
      removeItem: function(id) {
        delete localData["key".concat(id)];
        saveData();
        return jQuery.notify("Artikel von Merkliste entfernt",
  {
          autoHide: true,
          autoHideDelay: 2000,
          className: 'danger',
          position: 'top right'
        });
      },
      removeAll: function() {
        localData = {};
        return saveData();
      }
    };
    return serviceInstance;
  }
]);
